import React, { useState, useEffect } from "react";
import AsyncCreatableSelect from "react-select/async-creatable";
import { useNavigate } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import toast, { Toaster } from "react-hot-toast";
import { Helmet } from "react-helmet-async";
import { useSearchParams } from "react-router-dom";

import "font-awesome/css/font-awesome.min.css";

const Home = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [referredById] = useState(searchParams.get("r") || "1");
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [locationDescriptionList, setLocationDescriptionList] = useState([]);
  const [ownNumberRows, setOwnNumberRows] = useState(200);

  const [isValidEmail, setIsValidEmail] = useState(true);
  const [vanityName, setVanityName] = useState("");

  const [selectedRadius, setSelectedRadius] = useState(10);
  const [keywords, setKeywords] = useState("");
  const [companies, setCompanies] = useState("");

  const [selectedPQE, setSelectedPQE] = useState("");
  const [selectedPQETo, setSelectedPQETo] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [apiOrderToken, setApiOrderToken] = useState("");

  const validateEmail = (email) => {
    const regexPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regexPattern.test(email);
  };

  const apiURL = process.env.REACT_APP_API_URL;

  const customStyles = {
    option: (styles, state) => ({
      ...styles,
      boxShadow: "none",
      borderRadius: "20px",
      backgroundColor: state.isSelected ? "#ff7a00" : styles.color,
      color: "#000",
      "&:hover": {
        color: "#fff",
        backgroundColor: "#ff7a00",
      },
    }),
    control: (styles) => ({
      ...styles,
      // height: 52,
      boxShadow: "none",
      borderRadius: "20px",
      border: "1px solid #ccc",
      "&:hover": {
        color: "#fff",
        backgroundColor: "#fff",
      },
      "&:focus": {
        border: "1px solid #ff7a00",
      },
      "&:acitve": {
        border: "2px solid #ff7a00",
      },
    }),
  };

  const fetchLocationList = (inputValue, callback) => {
    let responseStatusCode = 0;

    fetch(apiURL + "lookupoutside/?name=LocationList::gb::" + inputValue, {
      method: "GET",
      headers: {
        charset: "UTF-8",
        "content-type": "application/json",
        accept: "application/json",
      },
    })
      .then((response) => {
        responseStatusCode = response.status;
        return response.json();
      })
      .then((response) => {
        if (responseStatusCode === 200 || responseStatusCode === 201) {
          let jsonResult = JSON.parse(response);
          // console.log(jsonResult);
          if (jsonResult !== null && jsonResult.length > 0) {
            callback(
              jsonResult.map((tmz) => ({
                value: tmz.Name,
                label: tmz.LocationShort,
              }))
            );
          }
        } else if (responseStatusCode === 401) {
          toast((t) => (
            <CustomToastContent
              message={
                "Oops - Could not load Location List - " + responseStatusCode
              }
              customClassName={
                "flex items-center justify-center text-gray-1400 bg-gray-200 border border-solid border-button_primary font-sofia-pro m-[-30px] p-3 rounded-[40px]"
              }
            />
          ));
        }
      })
      .catch((err) => {
        toast((t) => (
          <CustomToastContent
            message={err.message}
            customClassName={
              "flex items-center justify-center text-darkred bg-gray-200 border border-solid border-button_primary font-sofia-pro m-[-30px] p-3 rounded-[40px]"
            }
          />
        ));
      });
  };

  function EmailModal({ isOpen, onClose, onEmailSubmit, pageEmail }) {
    const [email, setEmail] = useState(pageEmail);
    const [isValidEmail, setIsValidEmail] = useState(true);

    const handleSubmit = (e) => {
      e.preventDefault();
      if (isValidEmailFormat(email)) {
        setIsValidEmail(true);
        // Pass the email to the parent component
        onEmailSubmit(email);
        onClose();
      } else {
        setIsValidEmail(false);
      }
    };

    const isValidEmailFormat = (email) => {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(email);
    };

    return (
      <div
        className={`modal ${
          isOpen ? "block" : "hidden"
        } fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 z-50 font-sofia-pro`}
      >
        <div className="modal-dialog bg-white rounded-lg p-6 w-80 mx-auto mt-24">
          <div className="flex items-center pb-2">
            <div className="text-2xl w-[70%] text-left relative h-[40px] shrink-0 font-semibold">
              Enter your email address
            </div>
            <div className="w-[30%] text-right justify-end">
              <div
                className="rounded-[40px] text-center font-semibold  box-border border border-solid border-brown-100 relative w-[38px] p-[0px_10px] leading-[28px] inline-block text-brown-100 capitalize cursor-pointer"
                onClick={onClose}
              >
                X
              </div>
            </div>
          </div>

          <form
            onSubmit={handleSubmit}
            className="text-center items-center"
            id="form1"
          >
            <input
              type="text"
              placeholder="Email address to receive list"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={`w-[90%] rounded-[40px] pt-2  border ${
                isValidEmail ? "" : "border-red-500"
              } rounded-md p-2 mb-2`}
            />
            {!isValidEmail && (
              <p className="text-red-500 text-sm">
                Please enter a valid email address.
              </p>
            )}
            <div className="flex pt-3">
              <button
                type="submit"
                className="mx-auto block bg-button_primary rounded-[40px] h-[48px] w-[70%] text-white border-button_primary items-center justify-center cursor-pointer"
              >
                Continue to order
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  const CustomToastContent = ({ message, customClassName }) => {
    return <div className={customClassName}>{message}</div>;
  };

  const order_send = (emailTyped) => {
    let responseStatusCode = 0;

    let formData = {};

    if (keywords !== null && keywords !== "") {
      formData.Keywords = encodeURIComponent(keywords);
    }

    formData.SkillList = "All";
    formData.CountryCode = "gb";

    if (selectedLocation !== null && selectedLocation !== "") {
      formData.LocationList = encodeURIComponent(selectedLocation);
    }

    if (referredById !== null && referredById !== "") {
      formData.ReferrerId = referredById;
    }

    if (ownNumberRows !== null && ownNumberRows !== "") {
      formData.NoOfRecordsRequested = ownNumberRows;
    }

    if (selectedPQE !== null && selectedPQE !== "") {
      formData.Pqe = selectedPQE;
    }

    if (selectedPQETo !== null && selectedPQETo !== "") {
      formData.PqeTo = selectedPQETo;
    }

    formData.TotalCost = 0;

    if (selectedRadius !== null) {
      formData.Radius = selectedRadius;
    } else {
      formData.Radius = 5;
    }

    formData.Quantity = 0;

    if (emailTyped !== null && emailTyped !== "") {
      formData.Email = encodeURIComponent(emailTyped);
    }

    formData.QueryType = 4;

    if (vanityName !== null && vanityName !== "") {
      formData.VanityNameList = encodeURIComponent(vanityName);
    }

    if (companies !== null && companies != "") {
      formData.Companies = encodeURIComponent(companies);
    }

    //console.log(JSON.stringify(formData))
    fetch(apiURL + "order", {
      method: "POST",
      headers: {
        charset: "UTF-8",
        "content-type": "application/json",
        accept: "application/json",
      },

      body: JSON.stringify(formData),
    })
      .then((response) => {
        responseStatusCode = response.status;
        return response.json();
      })
      .then((response) => {
        if (responseStatusCode === 200 || responseStatusCode === 201) {
          let jsonResult = JSON.parse(response);
          if (jsonResult[0] != null) {
            setApiOrderToken(jsonResult[0].api_order_token);
            //setPerformPayment(1);
            toast((t) => (
              <CustomToastContent
                message={"Successfully saved"}
                customClassName={
                  "flex items-center justify-center text-gray-1400 bg-gray-200 border border-solid border-button_primary font-sofia-pro m-[-30px] p-3 rounded-[40px]"
                }
              />
            ));
          }
        } else {
          toast((t) => (
            <CustomToastContent
              message={
                "Oops - could not save the request. " + responseStatusCode
              }
              customClassName={
                "flex items-center justify-center text-gray-1400 bg-gray-200 border border-solid border-button_primary font-sofia-pro m-[-30px] p-3 rounded-[40px]"
              }
            />
          ));
        }
      })
      .catch((err) => {
        toast((t) => (
          <CustomToastContent
            message={"Oops - " + err.message}
            customClassName={
              "flex items-center justify-center text-gray-1400 bg-gray-200 border border-solid border-button_primary font-sofia-pro m-[-30px] p-3 rounded-[40px]"
            }
          />
        ));
      });
  };

  let stripePromise;

  const currencyFractionDigits = new Intl.NumberFormat("en-UK", {
    style: "currency",
    currency: "EUR",
  }).resolvedOptions().maximumFractionDigits;

  const handleEmailSubmit = (email) => {
    // Handle the email submission here, such as sending it to a server
    setEmail(email);
    order_send(email);
  };

  const userEmail = ["hello", "eltic.io"].join("@");

  useEffect(() => {
    fetchLocationList();
  }, []);

  return (
    <>
      <Helmet>
        <title>Move Score List</title>
        <meta
          name="description"
          content="Use a Solicitor List with Move Scores to contact candidates"
        />
        <meta
          name="keywords"
          content="finding solicitors with a high propability to move job"
        />
      </Helmet>
      <div className="relative font-sofia-pro bg-cover bg-no-repeat bg-center h-full w-full">
        <div className="flex flex-col w-full h-screen box-border text-left min-h-screen text-normal font-sofia-pro ">
          <div className="flex flex-col w-full box-border items-center   ">
            <div className="flex w-[70%] lg:w-[80%] md:w-[90%] sm:w-full h-full items-end justify-center box-border">
              <div className="flex flex-col w-full bg-white text-left items-center justify-top font-sofia-pro box-border">
                <div className="flex flex-col w-full bg-white text-left items-center justify-top font-sofia-pro box-border">
                  <div className="flex flex-col items-center w-full">
                    <div className="w-full border flex flex-row">
                      <div className="flex justify-center items-center w-[50%]">
                        <img
                          src="/images/logoweb.png"
                          alt="ELTic"
                          className="cursor-pointer w-[160px] shrink-0 p-2"
                          onClick={() =>
                            (window.location.href = "https://eltic.io")
                          }
                        />
                      </div>
                      <div className="flex justify-center items-center w-[50%]">
                        <h1 className="text-8xl sm:text-2xl">
                          Move Score List
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="container mx-auto p-6 max-w-2xl">
                  <div className="text-2xl pt-1 text-center">
                    <h2 className="text-5xl sm:text-2xl">
                      What candidates are you looking for in the Legal Industry?
                    </h2>
                  </div>

                  <div className="mb-6">
                    <div className="w-full pt-2">
                      <label className="block pb-1 font-medium text-lg">
                        Keywords
                      </label>
                      <input
                        type="text"
                        name="keywords"
                        value={keywords}
                        onChange={(e) => setKeywords(e.target.value)}
                        placeholder="Keywords can be more than one word (e.g contentious probate) or two or more keywords separated by &"
                        className="border border-gray-300 h-10 rounded-md p-2 w-full shadow-sm"
                      />
                    </div>
                    <div className="pt-2 text-sm text-gray-600">
                      Examples:
                      <br />
                      <a
                        href="#"
                        onClick={() =>
                          setKeywords("Contentious Probate & Trusts")
                        }
                        className="text-blue-600 underline"
                      >
                        Contentious Probate & Trusts
                      </a>
                      <br />
                      <a
                        href="#"
                        onClick={() =>
                          setKeywords("Conveyancing & Residential")
                        }
                        className="text-blue-600 underline"
                      >
                        Conveyancing & Residential
                      </a>
                      <br />
                      <a
                        href="#"
                        onClick={() =>
                          setKeywords("Family & Mediation & Disputes")
                        }
                        className="text-blue-600 underline"
                      >
                        Family & Mediation & Disputes
                      </a>
                      <br />
                      <a
                        href="#"
                        onClick={() => setKeywords("Commercial & Litigation")}
                        className="text-blue-600 underline"
                      >
                        Commercial & Litigation
                      </a>
                    </div>

                    <div className="mb-6">
                      <br />
                      <label className="block pb-1 font-medium text-lg">
                        Location
                      </label>
                      <AsyncCreatableSelect
                        name="searchLocation"
                        styles={customStyles}
                        closeMenuOnSelect={true}
                        escapeClearsValue={false}
                        placeholder="Type to search ..."
                        onChange={(selectedOptions, action) => {
                          if (action != null) {
                            if (
                              action.action === "select-option" ||
                              action.action === "create-option"
                            ) {
                              if (Array.isArray(selectedOptions)) {
                                const selectedValues = selectedOptions.map(
                                  (option) => option.value
                                );

                                const selectedLabels = selectedOptions.map(
                                  (option) => option.label
                                );

                                setSelectedLocation(selectedLabels.join("|"));

                                setLocationDescriptionList(
                                  selectedValues.join("|")
                                );
                              } else {
                                setSelectedLocation(selectedOptions.value);
                              }
                            }

                            if (action.action === "clear") {
                              setSelectedLocation("");
                              setLocationDescriptionList("");
                              //setCalcReload(1);
                            }

                            if (
                              action.action === "remove-value" ||
                              action.action === "pop-value"
                            ) {
                              if (Array.isArray(selectedOptions)) {
                                // Handle removing a single option
                                const selectedValues = selectedOptions.map(
                                  (option) => option.value
                                );

                                const selectedLabels = selectedOptions.map(
                                  (option) => option.label
                                );
                                setSelectedLocation(selectedLabels.join("|"));
                                setLocationDescriptionList(
                                  selectedValues.join("|")
                                );
                              }
                            }
                          }
                        }}
                        loadOptions={fetchLocationList}
                        className="border border-gray-300 h-10 rounded-md p-2 w-full shadow-sm z-[4]"
                      />
                    </div>
                    <div className="mb-6">
                      <label className="block pb-1 font-medium text-lg">
                        Miles around location
                      </label>
                      <input
                        type="text"
                        name="radius"
                        value={selectedRadius}
                        defaultValue={10}
                        onBlur={(e) => {
                          setSelectedRadius(e.target.value);
                        }}
                        onChange={(e) => setSelectedRadius(e.target.value)}
                        className="border border-gray-300 h-[25px] rounded-md p-2 w-[100px] shadow-sm"
                      />
                    </div>
                    <div className="w-full">
                      <label className="block pb-1 font-sofia-pro text-xl font-medium text-normal">
                        Professional Qualified Experience (PQE) years
                      </label>
                      <label className="font-sofia-pro text-sm font-medium text-normal">
                        &nbsp;&nbsp;from:&nbsp;
                      </label>
                      <input
                        type="text"
                        name="searchPQE"
                        value={selectedPQE}
                        onBlur={(e) => {
                          setSelectedPQE(e.target.value);
                        }}
                        onChange={(e) => setSelectedPQE(e.target.value)}
                        className="border border-light h-[25px] rounded-md p-2 text-2xl font-sofia-pro w-[50px]"
                      />
                      &nbsp;&nbsp;
                      <label className="font-sofia-pro text-sm font-medium text-normal">
                        to:&nbsp;
                      </label>
                      <input
                        type="text"
                        name="searchPQETo"
                        value={selectedPQETo}
                        onBlur={(e) => {
                          setSelectedPQETo(e.target.value);
                        }}
                        onChange={(e) => setSelectedPQETo(e.target.value)}
                        className="border border-light h-[25px] rounded-md p-2 text-2xl font-sofia-pro w-[50px]"
                      />
                    </div>
                    <div className="w-full pt-3">
                      <label className="block pb-1 font-medium text-lg">
                        Employed by Company (Optional) - separate company names with |
                      </label>
                      <input
                        type="text"
                        name="companies"
                        value={companies}
                        onChange={(e) => setCompanies(e.target.value)}
                        placeholder=""
                        className="border border-light h-[30px] rounded-md p-2 text-2xl font-sofia-pro w-full"
                      />
                    </div>
                    <div className="w-full pt-4">
                      <div className="block pb-1 font-medium text-lg">
                        <label className="block font-sofia-pro text-xl font-medium text-normal">
                          How many records do you need:
                        </label>
                       
                        <input
                          name="ownNumber"
                          value={ownNumberRows}
                          size="6"
                          className="border border-light h-[30px] rounded-md p-2 text-2xl font-sofia-pro w-[100px]"
                          onChange={(e) => {
                            setOwnNumberRows(e.target.value);
                            row_own_number_calc(e.target.value, orderTypeId);
                          }}
                        />
                      </div>
                    </div>
                    <div className="w-full">
                      <div className="flex justify-between pt-7 gap-4 mb-4">
                        {ownNumberRows === 0}
                        <button
                          onClick={() => setIsModalOpen(true)}
                          disabled={ownNumberRows === 0}
                          className="flex flex-row bg-button_primary self-stretch disabled:text-gray-500 rounded-[40px] h-[48px] w-full text-white box-border border-button_primary items-center justify-center gap-[10px] cursor-pointer"
                        >
                          <div className="relative font-semibold text-xl">
                            Request List
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col text-base pt-4 h-[60px] sm:h-[160px] bg-white/80 items-center justify-center text-center font-semibold text-normal w-full box-border ">
              <div className="flex flex-row flex-wrap w-[90%] items-center justify-center gap-3 box-border">
                <div
                  className="text-normal underline cursor-pointer"
                  onClick={() => (window.location.href = "https://eltic.io")}
                >
                  Home
                </div>
                <div>
                  <a href={`mailto:${userEmail}`} className="text-normal ">
                    {userEmail}
                  </a>
                </div>

                <div
                  className="text-normal underline cursor-pointer"
                  onClick={() => navigate("/privacypolicy")}
                >
                  Privacy
                </div>
                <div
                  className="text-normal underline cursor-pointer"
                  onClick={() => navigate("/termsandconditions")}
                >
                  T&C
                </div>
                <div className="text-normal">
                  &copy; 2023 - All Rights Reserved ELTic Ltd
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EmailModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onEmailSubmit={handleEmailSubmit}
        pageEmail={email}
      />
      <Toaster />
    </>
  );
};

export default Home;
